<template>
    <div class="d-flex w-100 chat-contact-card" :class="[{ active: $route.params.phone == contact.phone }, isMobileSmall ? 'p-2' : 'px-4 py-3 ']">
        <b-avatar
            size="40"
            :src="contact.profile_img"
            onerror="this.src=/assets/img/default-user.jpg"
            class="mr-3 cursor-pointer"
            :badge="bagdeContact"
            :badge-variant="contact.suggest != null ? 'primary' : 'dark-orange'"
        />
        <div class="d-flex flex-column flex-grow-1">
            <div class="d-flex align-items-start" :class="[!isMobileSmall ? 'justify-content-between' : '']">
                <div class="d-flex align-items-center flex-wrap">
                    <h5 class="chat-title text-truncate text-left" :class="[`${getColor()} mb-0 mr-2`, { inactive: contact.is_blocked }]">
                        {{ name }}
                    </h5>
                    <small
                        v-b-tooltip.hover.right="contact.mark.name"
                        :style="{ color: contact.mark.color }"
                        v-if="contact.mark != null"
                    >
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style="transform: rotateY(180deg);">
                            <path
                                d="M2.59 11.58L11.59 2.58C11.95 2.22 12.45 2 13 2H20C21.1 2 22 2.9 22 4V11C22 11.55 21.78 12.05 21.41 12.42L12.41 21.42C12.05 21.78 11.55 22 11 22C10.45 22 9.95 21.78 9.59 21.41L2.59 14.41C2.22 14.05 2 13.55 2 13C2 12.45 2.23 11.94 2.59 11.58ZM11 20.01L20 11V4H13V3.99L4 12.99L11 20.01V20.01ZM16 6.5C16 7.33 16.67 8 17.5 8C18.33 8 19 7.33 19 6.5C19 5.67 18.33 5 17.5 5C16.67 5 16 5.67 16 6.5Z"
                                :fill="contact.mark.color"/>
                        </svg>
                    </small>
                </div>

                <small v-if="contact.last_message != null && contact.last_message.time != null">
                    {{ getShortStringTime(contact.last_message.time) }}
                </small>
            </div>

            <div class="d-flex justify-content-between">
                <div v-if="getMessagePreview().constructor == Array">
                    <p
                        v-for="(preview, i) in getMessagePreview()"
                        class="text-dark text-left text-truncate w-100 m-0 chat-preview-text text-muted"
                        :key="`message-preview-${i}-${uuid}`"

                    >
                        <router-link
                            custom
                            v-slot="{ href, navigate }"
                            :to="{ name: 'chats/show', params: { phone: contact.phone }, query: { message: preview._id } }"
                        >
                            <a
                                @click="navigate"
                                :href="href"
                                v-html="preview.text"
                                class="text-muted"
                            ></a>
                        </router-link>
                    </p>
                </div>
                <p v-else class="text-dark text-left text-truncate w-100 m-0 mt-1 chat-preview-text">
                    {{ getMessagePreview() }}
                </p>
                
                <button @click.stop :id="`menu-${uuid}`" tabindex="0" class="border-none btn-custom text-blue">
                    <font-awesome-icon icon="fa-solid fa-ellipsis-vertical" class="p-2 menu-ellipses border-none" style="outline: none;" />
                </button>
                <b-popover @hide="remember = null" :target="`menu-${uuid}`" placement="right" custom-class="popover-dropdown" triggers="focus">
                    <div class="d-flex flex-column">
                        <a 
                            @click="markAsUnread" 
                            class="w-100 p-2 text-decoration-none" 
                            role="button"
                            v-if="Number(contact.unreaded_messages) == 0"
                        >
                            Marcar como não lido
                        </a>
                        <a v-b-toggle="`marksSidebarContact${contact._id}`" v-if="$role('chats.update')" class="w-100 p-2 text-decoration-none" role="button">
                            Adicionar marcador
                        </a>
                        <a @click="() => addRememberTime()" class="w-100 p-2 text-decoration-none" role="button">
                            Lembre-me <font-awesome-icon :icon="`fa-solid ${remember == null ? 'fa-chevron-down' : 'fa-chevron-up'}`" size="xs" class="mx-2"/>
                        </a>
                        <div v-if="remember != null" class="px-3 pb-2 d-flex flex-column">
                            <a @click="addRememberTime(5)" class="w-100 text-secondary py-2 text-decoration-none" role="button">
                                5 minutos
                            </a>
                            <a @click="addRememberTime(10)" class="w-100 text-secondary py-2 text-decoration-none" role="button">
                                10 minutos
                            </a>
                            <a @click="addRememberTime(60)" class="w-100 text-secondary py-2 text-decoration-none" role="button">
                                1 hora
                            </a>
                            <a @click="addRememberTime(60 * 24)" class="w-100 text-secondary py-2 text-decoration-none" role="button">
                                1 dia
                            </a>
                            <a @click="addRememberTime(60 * 24 * 7)" class="w-100 text-secondary py-2 text-decoration-none" role="button">
                                1 semana
                            </a>
                            <input v-model="remember" type="datetime-local" class="form-control">
                            <button :disabled="loading" @click="sendRemember" class="btn btn-primary btn-sm mt-2">
                                <span v-if="loading" class="spinner-border spinner-border-sm" role="status"></span>
                                <span v-else>Lembrar</span>
                            </button>
                        </div>
                    </div>
                </b-popover>
            </div>
        </div> 
        <div v-if="selectable" class="d-flex flex-column justify-content-center align-items-center">
            <input :checked="selected" type="checkbox" class="form-checkbox ml-3">
        </div>
        <marks-sidebar :id="`marksSidebarContact${contact._id}`" v-if="$role('chats.update')" :value="contact"/>
    </div>
</template>

<script>
import { BAvatar, BPopover } from 'bootstrap-vue';
import { uuid } from 'vue-uuid';
import { clearTagsMessage, getShortStringTime } from '@/api/functions';
import api from '@/api';
import { formatPhone } from "@/api/functions";
import MarksSidebar from '../modals/MarksSidebar.vue';
import { chatTypes } from '../constants';

export default {
    components: {
        BAvatar,
        BPopover,
        MarksSidebar
    },
    props: {
        contact: {
            type: Object,
            required: true
        },
        selectable: {
            type: Boolean,
            default: false
        },
        selected: {
            type: Boolean,
            default: false
        },
        isMobileSmall: {
            type: Boolean,
            default: false
        }
    },
    emits: ['select', 'last-message-update'],
    data() {
        return {
            uuid: uuid.v4(),
            remember: null,
            loading: false
        }
    },
    computed: {
        chatType() {
            const status = this.$props.contact.status || 'active';
            return chatTypes.find((value) => value.id == status);
        },
        bagdeContact() {
            const contact = this.$props.contact;
            
            if(contact.unreaded_messages > 0)
                return String(contact.unreaded_messages);

            if(contact.status == 'pending')
                return true;

            return null
        },
        name() {
            if (this.contact.customer)
                return this.contact.customer.name;

            const phone =  this.contact.phone;
            
            return formatPhone(phone);
        }
    },
    watch: {
        "$store.state.chat": {
            handler: function() {
                this.$forceUpdate();
            },
            deep: true
        },
        '$props.contact.last_message': {
            handler: function() {
                this.$emit('last-message-update');
            },
            deep: true
        }
    },
    methods: {
        getShortStringTime,
        markAsUnread() {
            this.loading = true;

            api.put(`/chats/${this.contact._id}`, { readed: false })
            .then(response => {
                const { type } = response.data;

                if(type != 'success')
                    throw response;

                const contact = { ...this.contact, unreaded_messages: Number(this.contact.unreaded_messages) + 1 };
                this.$store.commit('chat/SET_CONTACT', contact);
                this.$store.commit('chat/SET_UNREADED_MESSAGES', Number(this.$store.state.chat.unreadedMessages) + 1)
            })
            .catch(() => {
                return this.$bvToast.toast('Não foi possível marcar como não lida.', {
                    title: 'Contatos',
                    variant: 'danger'
                });
            })
            .finally(() => this.loading = false)
        },
        sendRemember() {
            this.loading = true;
            const payload = {
                title: 'Lembrete de Chat',
                message: `Lembre-se de visualizar sua conversa com ${this.contact.customer ? this.contact.customer.name : this.contact.name}`,
                redirect: {
                    name: 'chats/show',
                    params: {
                        phone: this.contact.phone
                    }
                },
                date: this.remember,
                type: 'reminder',
                config: {
                    contact_id: this.contact._id
                }
            };

            api.post('/notifications', payload)
            .then(response => {
                const { type } = response.data;

                if(type == 'success') {
                    this.remember = null;
                    return this.$bvToast.toast('Lembrete agendado com sucesso.', {
                        title: 'Contatos',
                        variant: 'success'
                    });
                }
                throw response;
            })
            .catch(() => {
                return this.$bvToast.toast('Não foi possível agendar lembrete.', {
                    title: 'Contatos',
                    variant: 'danger'
                });
            })
            .finally(() => this.loading = false)
        },
        addRememberTime(minutes = 0) {
            if (this.remember && minutes === 0)
                return this.remember = null;

            var date = new Date();

            if(minutes > 0) date = new Date(Date.now() + 60000 * minutes);

            var dateStr = date.getFullYear() + "-" +
                ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
                ("00" + date.getDate()).slice(-2) + " " +
                ("00" + date.getHours()).slice(-2) + ":" +
                ("00" + date.getMinutes()).slice(-2) + ":" +
                ("00" + date.getSeconds()).slice(-2);

            this.remember = dateStr;
        },
        getMessagePreview() {
            const contact = this.contact;
            if(contact.search_messages && contact.search_neddles && contact.search_messages.length > 0) {
                const neddles = contact.search_neddles;
                const messages = contact.search_messages;

                return Array.from(messages).map(message => {
                    let text = message.message;
                    text = clearTagsMessage(text);
                    neddles.forEach(neddle => {
                        text = text.replaceAll(new RegExp(`(${neddle})`, 'ig'), `<span class="text-blue font-weight-bold">$1</span>`);
                    })
                    return {
                        _id: message._id,
                        text: `${this.getShortStringTime(message.time)}: ${text}`
                    };
                });
            }

            const lastMessage = contact.last_message;
            if(lastMessage) {
                if(lastMessage.message == null) {
                    const name = contact.customer != null ? contact.customer.name : contact.name;
                    let sufix = "enviou um arquivo";
                    if(lastMessage.file != null && lastMessage.file.mime) {
                        if (lastMessage.file.mime === 'image/webp')
                            sufix = 'enviou uma figurinha';
                        else if (lastMessage.file.type === 'image')
                            sufix = 'enviou uma imagem';
                        else if (lastMessage.file.type === 'video')
                            sufix = 'enviou um vídeo';
                        else if (lastMessage.file.type === 'audio')
                            sufix = 'enviou um áudio';
                    }

                    if(lastMessage.from_me)
                        return `Você ${sufix}`;

                    return `${name} ${sufix}`;
                }
                return clearTagsMessage(lastMessage.message);
            }

            return 'Clique para acessar chat';
        },
        getColor() {
            const contact = this.contact;
            if(contact && contact.transfer)
                return 'text-pink';

            if(contact.suggest != null)
                return 'text-light-pink';

            if(contact.is_foreign & contact.status == 'active')
                return 'text-saturated-blue';

            if(this.chatType)
                return `text-${this.chatType.color}`
            
            return 'text-blue';
        }
    }
}
</script>

<style lang="scss">
    .chat-contact-card {
        transition: 300ms;
        border-bottom: solid 1px #EEE;
        cursor: pointer;
        max-width: 383px;
        overflow: hidden;

        .chat-title {
            max-width: 220px;
        }

        .menu-ellipses {
            transition: 300ms;
            opacity: 0;
        }

        &:hover {
            background-color: #F6F6F6;
            .menu-ellipses {
                opacity: 1;
            }
        }

        .inactive {
            color: #333 !important;
        }

        &.active {
            background-color: #F0F0F0;
        }
    }

    .chat-preview-text {
        max-width: 240px;
        line-height: 1.3rem;
        max-height: 1.3rem;
        
        @media screen and (max-width: 374px) {
         max-width: 180px;   
        }
    }

    .btn-custom {
        padding: 0;
        height: 28px;
        margin: 0;
    }

    .btn-custom,
    .btn-custom:focus,
    .btn-custom:active,
    .btn-custom:hover {
        border: none;
        box-shadow: none !important;
        background: transparent !important;
    }
</style>
