<template>
    <div>
        <button @click.stop :id="uniquePrefix('popover')" tabindex="0" class="border-none btn-custom">
            <font-awesome-icon icon="fa-solid fa-ellipsis-vertical" class="p-2 menu-ellipses border-none" style="outline: none;" />
        </button>

        <b-popover 
            :target="uniquePrefix('popover')" 
            placement="left" 
            custom-class="popover-dropdown" 
            triggers="focus"
            @hide="close"
        >
            <div class="d-flex flex-column">
                <a
                    v-if="$role('chats.update')"
                    role="button"
                    class="w-100 p-2 text-decoration-none"
                    v-b-modal="uniquePrefix('select-mark')"
                >
                    Alterar Marcador
                </a>
                <a
                    v-if="$role('chats.update')"
                    role="button"
                    class="w-100 p-2 text-decoration-none"
                    @click="status = !status"
                >
                    <span>Alterar Status</span>
                    <font-awesome-icon 
                        :icon="`fa-solid ${!status ? 'fa-chevron-down' : 'fa-chevron-up'}`" 
                        size="xs" 
                        :class="!status ? 'mx-2' : 'mx-2 mt-1'"
                    />
                </a>

                <div v-if="status" class="px-3 pb-2 d-flex flex-column">
                    <a 
                        v-for="chatType in chatTypes"
                        :key="chatType.id"
                        @click.prevent.stop="() => changeStatus(chatType.id)"
                        role="button"
                        class="w-100 py-2 text-decoration-none"
                    >
                        <font-awesome-icon
                            :class="'text-' + chatType.color"
                            :icon="'fa-solid ' + chatType.icon"
                        />
                        <span :class="'text-' + chatType.color">
                            {{ chatType.label }}
                        </span>
                    </a>
                </div>

                <a
                    v-if="$role('chats.block')"
                    role="button"
                    class="w-100 p-2 text-decoration-none"
                    @click="() => blockContacts()"
                >
                    Bloquear
                </a>
                <a
                    role="button"
                    class="w-100 p-2 text-decoration-none"
                    v-b-modal="uniquePrefix('send-message')"
                >
                    Enviar mensagem
                </a>
                <a
                    v-if="$role('chats.update')"
                    role="button"
                    class="w-100 p-2 text-decoration-none"
                    @click="() => markAsUnread()"
                >
                    Marcar como não lido
                </a>
                <a
                    role="button"
                    class="w-100 p-2 text-decoration-none d-flex align-items-center"
                    @click="() => addRememberTime()"
                >
                    <span>Lembre-me</span>
                    <font-awesome-icon 
                        :icon="`fa-solid ${remember == null ? 'fa-chevron-down' : 'fa-chevron-up'}`" 
                        size="xs" 
                        :class="remember == null ? 'mx-2' : 'mx-2 mt-1'"
                    />
                </a>
                <div v-if="remember != null" class="px-3 pb-2 d-flex flex-column">
                    <a @click="addRememberTime(5)" class="w-100 text-secondary py-2 text-decoration-none" role="button">
                        5 minutos
                    </a>
                    <a @click="addRememberTime(10)" class="w-100 text-secondary py-2 text-decoration-none" role="button">
                        10 minutos
                    </a>
                    <a @click="addRememberTime(60)" class="w-100 text-secondary py-2 text-decoration-none" role="button">
                        1 hora
                    </a>
                    <a @click="addRememberTime(60 * 24)" class="w-100 text-secondary py-2 text-decoration-none" role="button">
                        1 dia
                    </a>
                    <a @click="addRememberTime(60 * 24 * 7)" class="w-100 text-secondary py-2 text-decoration-none" role="button">
                        1 semana
                    </a>
                    <input v-model="remember" type="datetime-local" class="form-control">
                    <button :disabled="loading" @click="sendRemember" class="btn btn-primary btn-sm mt-2">
                        <span v-if="loading" class="spinner-border spinner-border-sm" role="status"></span>
                        <span v-else>Lembrar</span>
                    </button>
                </div>
                <a
                    role="button"
                    class="w-100 p-2 text-decoration-none"
                    v-b-modal="uniquePrefix('transfer-chat')"
                >
                    Transferir
                </a>
            </div>
        </b-popover>

        <send-message-modal 
            :id="uniquePrefix('send-message')" 
            :chats="value"
        />
        
        <transfer-chat-modal 
            :id="uniquePrefix('transfer-chat')"
            v-if="$role('chats.update')"
            @input="clear" 
            :value="value"
        />

        <select-mark-modal
            :id="uniquePrefix('select-mark')"
            v-if="$role('chats.update')"
            @input="changeMark"
        />
    </div>
</template>

<script>
import { uuid } from "vue-uuid";
import SendMessageModal from "../modals/SendMessageModal.vue";
import TransferChatModal from "../modals/TransferChatModal.vue";
import { BPopover } from 'bootstrap-vue';
import api from "@/api";
import { chatTypes } from "../constants";
import SelectMarkModal from "../modals/SelectMarkModal.vue";

export default {
    components: {
        BPopover,
        SendMessageModal,
        TransferChatModal,
        SelectMarkModal
    },
    props: {
        value: {
            type: Array,
            required: true
        }
    },
    emits: ['input', 'update'],
    data() {
        return {
            uuid: uuid.v4(),
            remember: null,
            loading: false,
            status: false,
            chatTypes
        }
    },
    methods: {
        /**
         * Remove a seleção de todos os contatos e dispara um evento
         * para que os contatos sejam carregados novamente.
         */
        clear() {
            this.$emit('input', null);
            this.$emit('update', null);
        },
        /**
         * Reseta o estado do popover ao fecha-lo.
         */
        close() {
            this.remember = null;
            this.status = false;
        },
        /**
         * Gera um id único com prefixo para os modals.
         * @param {String} prefix
         */
        uniquePrefix(prefix) {
            return `${prefix}-${this.uuid}`;
        },
        /**
         * Adiciona o tempo ao input de lembrar-me
         * @param {int} minutes
         */
        addRememberTime(minutes = 0) {
            if (minutes == 0 && this.remember)
                return this.remember = null;
                
            var date = new Date();

            if(minutes > 0) date = new Date(Date.now() + 60000 * minutes);

            var dateStr = date.getFullYear() + "-" +
                ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
                ("00" + date.getDate()).slice(-2) + " " +
                ("00" + date.getHours()).slice(-2) + ":" +
                ("00" + date.getMinutes()).slice(-2) + ":" +
                ("00" + date.getSeconds()).slice(-2);

            this.remember = dateStr;
        },
        /**
         * Envia a requisição para configurar o lembrete
         */
        sendRemember() {
            this.$swal({
                title: 'Agendando notificações',
                didOpen: () => {
                    this.$swal.showLoading()

                    const promises = this.value.map(contact => {
                        const payload = {
                            title: 'Lembrete de Chat',
                            message: `Lembre-se de visualizar sua conversa com ${contact.customer ? contact.customer.name : contact.name}`,
                            redirect: {
                                name: 'chats/show',
                                params: {
                                    phone: contact.phone
                                }
                            },
                            date: this.remember,
                            type: 'reminder',
                            config: {
                                contact_id: contact._id
                            }
                        };

                        return api.post('/notifications', payload);
                    });

                    Promise
                        .all(promises)
                        .then(() => {
                            return this.$root.$bvToast.toast('Lembretes agendados com sucesso.', {
                                title: 'Contatos',
                                variant: 'success'
                            });
                        })
                        .catch(() => {
                            return this.$root.$bvToast.toast('Não foi possível agendar lembrete.', {
                                title: 'Contatos',
                                variant: 'danger'
                            });
                        })
                        .finally(() => this.$swal.close());
                },
            });
        },
        /**
         * Marca a última mensagem de cada contato como não lida
         */
        markAsUnread() {
            this.$swal({
                title: 'Marcando contatos como não lidos',
                didOpen: () => {
                    this.$swal.showLoading();

                    const promises = this.value.map(contact => {
                        return api.put(`/chats/${contact._id}`, { readed: false });
                    });

                    Promise
                        .all(promises)
                        .then(() => {
                            return this.$root.$bvToast.toast('Contatos marcados como não lidos.', {
                                title: 'Contatos',
                                variant: 'success'
                            });
                        })
                        .catch(() => {
                            return this.$root.$bvToast.toast('Não foi possível marcar como não lida.', {
                                title: 'Contatos',
                                variant: 'danger'
                            });
                        })
                        .finally(() => {
                            this.$swal.close();
                            this.clear();
                        });
                },
            });
        },
        /**
         * Bloqueia os contatos
         */
        blockContacts() {
            this.$swal({
                title: 'Tem certeza que deseja bloquear estes contatos?',
                text:  'As futuras mensagens com estes contatos serão ignoradas. Os contatos não serão bloqueados nas redes.',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Bloquear',
                confirmButtonColor: "#317f98",
                reverseButtons: true,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    const promises = this.value.map(contact => {
                        return api.put(`/chats/${contact._id}`, { is_blocked: true });
                    });

                    return Promise.all(promises);
                }
            })

                .then(result => {
                    if (result.isConfirmed) {
                        this.$root.$bvToast.toast('Contatos bloqueados.', {
                            title: 'Contatos',
                            variant: 'success'
                        });
                        this.$swal.close();
                        this.clear();
                    }
                })
                .catch(() => {
                    return this.$bvToast.toast('Não foi possível bloquear contato.', {
                        title: 'Contatos',
                        variant: 'danger'
                    });
                })
        },
        /**
         * Altera os status dos contatos
         */
        changeStatus(status) {
            this.$swal({
                title: `Alterando status dos contatos para ${status}`,
                didOpen: () => {
                    this.$swal.showLoading();

                    const promises = this.value.map(contact => {
                        return api.put(`/chats/${contact._id}`, { status })
                    });
                    
                    Promise
                        .all(promises)
                        .then(() => {
                            return this.$root.$bvToast.toast('Status atualizados com sucesso.', {
                                title: 'Contatos',
                                variant: 'success'
                            });
                        })
                        .catch(() => {
                            return this.$root.$bvToast.toast('Não foi possível atualizar o status dos contatos.', {
                                title: 'Contatos',
                                variant: 'danger'
                            });
                        })
                        .finally(() => {
                            this.$swal.close();
                            this.clear();
                        });
                },
            });
        },
        /**
         * Altera o marcador dos contatos.
         */
        changeMark(mark) {
            this.$swal({
                title: `Alterando marcador dos contatos`,
                didOpen: () => {
                    this.$swal.showLoading();

                    const promises = this.value.map(contact => {
                        return api.put(`/chats/${contact._id}`, { mark_id: mark == -1 ? null : mark });
                    });
                    
                    Promise
                        .all(promises)
                        .then(() => {
                            return this.$root.$bvToast.toast('Marcadores alterados com sucesso.', {
                                title: 'Contatos',
                                variant: 'success'
                            });
                        })
                        .catch(() => {
                            return this.$root.$bvToast.toast('Não foi possível alterar o marcador dos contatos.', {
                                title: 'Contatos',
                                variant: 'danger'
                            });
                        })
                        .finally(() => {
                            this.$swal.close();
                            this.clear();
                        });
                },
            });
        }
    }
};
</script>
