<template>
    <section id="dashboard-chat" class="chat-application h-100 w-100 d-flex flex-grow-1">
        <b-card class="rounded overflow-hidden d-flex h-100 w-100 flex-grow-1" no-body>
            <b-card-body class="p-0 d-flex flex-grow-1">
                <div class="body-content-overlay d-flex flex-grow-1"></div>
                <div class="d-flex w-100 flex-grow-1">
                    <div class="content-left">
                        <chat-sidebar :is-mobile-large="isMobileLarge" :is-mobile-small="isMobileSmall" />
                    </div>
                    <div class="content-right flex-grow-1">
                        <!-- Main Area -->
                        <section class="chat-app-window d-flex flex-grow-1">
                            <!-- Start Chat Logo -->
                            <div v-if="$route.params.phone === undefined" class="start-chat-area w-100 flex-grow-1">
                                <feather-icon
                                    icon="MessageSquareIcon"
                                    class="mb-2"
                                    size="56"
                                />

                                <p>Selecione uma conversa para visualiza-la</p>
                            </div>
                            <!-- Chat Content -->
                            <chat-messages v-else :phone="$route.params.phone" />
                        </section>
                    </div>
                </div>
            </b-card-body>
        </b-card>
    </section>
</template>

<script>
import {
    BCard,
    BCardBody
} from "bootstrap-vue";
import ChatMessages from "@/views/pages/dashboard/messages/ChatMessages.vue"
import ChatSidebar from "@/views/pages/dashboard/messages/ChatSidebar.vue"

export default {
    components: {
        BCard,
        BCardBody,
        ChatSidebar,
        ChatMessages,
    },
    computed: {
        isMobileLarge() {
            return screen.width <= 768 ? 'show' : '';
        },
        isMobileSmall() {
            return screen.width < 425 ? true : false;
        }
    }
};
</script>
