<template>
    <div v-if="value != null" class="d-flex flex-wrap" style="font-size: 1.5rem;">
        <span
            v-for="(tag, i) in value"
            :key="`filter-tags-${i}`"
            class="btn button py-1 px-2 text-blue d-flex align-items-center text-left mr-2 mb-2"
            style="font-size: 0.7rem;"
        >
            {{ keyLabel != null ? tag[keyLabel] : tag }}
            <font-awesome-icon
                class="ml-2"
                icon="fa-solid fa-close"
                size="sm"
                @click="removeTag(i)"
            />
        </span>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
            required: true
        },
        keyLabel: {
            type: String
        },
    },
    emits: ['input'],
    methods: {
        removeTag(index) {
            const array = Array.from(this.value);
            array.splice(index, 1);
            this.$emit('input', array);
        }
    }
}
</script>
