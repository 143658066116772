<template>
    <b-modal
        :id="id"
        centered
        title="Selecionar marcador"
        header-border-variant="light"
        footer-border-variant="light"
        content-class="p-4 position-relative"
        body-class="position-unset"
        :no-close-on-backdrop="loading"
        :no-close-on-esc="loading"
        @show="load"
        @hide="close"
    >
        <loading v-if="loading" />

        <validation-observer ref="selectMarkForm">
            <validation-provider
                #default="{ errors }"
                rules="required"
                class="w-100"
            >
                <select 
                    v-model="selected"
                    name="mark" 
                    class="form-control m-0" 
                    :class="{ 'is-invalid': errors.length > 0 }"
                >
                    <option :value="null">Selecione uma opção</option>
                    <option :value="-1">Remover marcador</option>
                    <option
                        v-for="m in marks" 
                        :value="m._id" 
                        :key="m._id"
                    >
                        {{ m.name }}
                    </option>
                </select>
            </validation-provider>
        </validation-observer>

        <template #modal-footer="{ cancel, ok }">
            <button @click="cancel" class="btn btn-outline-primary px-5 mr-2">
                Cancelar
            </button>
            <button @click="submit(ok)" class="btn btn-primary px-5">
                Salvar
            </button>
        </template>
    </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { BModal } from "bootstrap-vue";
import Loading from "@/views/components/Loading.vue";
import { required } from "@validations";
import api from "@/api";

export default {
    components: {
        BModal,
        ValidationProvider,
        ValidationObserver,
        Loading
    },
    props: {
        value: {
            type: Object,
            required: false
        },
        id: {
            type: String,
            default: () => 'selectMarkModal'
        }
    },
    data() {
        return {
            required,
            marks: [],
            selected: null,
            loading: false
        };
    },
    emits: ['save', 'input'],
    methods: {
        close() {
            this.selected = null;
            this.marks = [];
        },
        submit(closeHandle) {
            this.$refs.selectMarkForm.validate().then(success => {
                if(!success)
                    return;

                this.$emit('input', this.selected);
                closeHandle();
            })
        },
        load() {
            this.loading = true;
            api.get('marks', { params: { all: 1 } })
                .then(res => {
                    const { body, type } = res.data;

                    if (type == 'success')
                        return this.marks = body;
                    
                    this.marks = [];
                })
                .catch(() => this.$root.$bvToast.toast('Não foi possível carregar os marcadores disponíveis', { variant: 'danger' }))
                .finally(() => this.loading = false);
        }
    },
}
</script>

<style>
.position-unset {
    position: unset !important;
}
</style>
