<template>
    <div class="sidebar-container h-100" :class="[isMobileLarge]">
        <div class="sidebar-content d-flex flex-column h-100">
            <div class="full-width">
                <filters-bar v-model="filters" @select="() => selectedContacts = []" :is-mobile-small="isMobileSmall"/>
                <order-modal v-model="order"/>
            </div>

            <p v-if="contacts.length === 0 && !loading">Nenhum contato encontrado</p>

            <vue-perfect-scrollbar
                id="chat-scrollbar"
                class="chat-user-list-wrapper list-group scroll-area flex-grow-1"
                @ps-scroll-y="loadMore"
            >
                <transition-group name="contacts" tag="ul" class="chat-users-list chat-list media-list">
                    <li
                        v-for="contact in contacts"
                        :key="`contact-${contact._id}`"
                        class="p-0"
                        @click="toogleChat"
                    >
                        <router-link
                            v-if="selectedContacts === null"
                            custom
                            :to="{ name: 'chats/show', params: { phone: contact.phone } }"
                            v-slot="{ navigate }"

                        >
                            <chat-contact
                                :contact="contact"
                                @click.native="navigate"
                                :style="loading ? 'opacity: 0.6' : ''"
                                is-chat-contact
                                @last-message-update="forceOrder"
                                :is-mobile-small="isMobileSmall"
                            />
                        </router-link>

                        <chat-contact
                            v-else
                            :contact="contact"
                            @click.native="toggleContact(contact)"
                            is-chat-contact
                            :style="loading ? 'opacity: 0.6' : ''"
                            :selectable="true"
                            @last-message-update="forceOrder"
                            :selected="selectedContacts !== null && selectedContacts.includes(contact)"
                        />
                    </li>
                </transition-group>

                <div v-if="loading" class="d-flex w-100 align-items-center justify-content-center">
                    <span class="spinner-border mt-4" role="status"></span>
                </div>
            </vue-perfect-scrollbar>

            <div v-if="selectedContacts !== null" class="full-width">
                <div class="p-3 d-flex justify-content-between align-items-center border-top-secondary">
                    <div>
                        <font-awesome-icon 
                            @click="selectedContacts = null" 
                            size="sm" 
                            class="mx-2" 
                            icon="fa-solid fa-close" 
                        />
                        <span v-if="selectedContacts.length > 0">
                            {{ selectedContacts.length }} {{ selectedContacts.length === 1 ? 'contato selecionado' : 'contatos selecionados'}}
                        </span>
                        <span v-else>
                            Nenhum contato selecionado
                        </span>
                    </div>

                    <chat-select-options
                        v-if="selectedContacts.length > 0"
                        v-model="selectedContacts"
                        @update="getContacts"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import ChatContact from "./components/ChatContact.vue";
import FiltersBar from "@/views/pages/dashboard/messages/components/FiltersBar";
import OrderModal from "@/views/pages/dashboard/messages/modals/OrderModal";
import api from "@/api";
import { filtersModel, orderModel } from './constants';
import ChatSelectOptions from "./components/ChatSelectOptions.vue";

export default {
    components: {
        VuePerfectScrollbar,
        FiltersBar,
        ChatContact,
        OrderModal,
        ChatSelectOptions
    },
    data() {
        return {
            pagination: null,
            filters: Object.assign({}, filtersModel),
            order: Object.assign({}, orderModel),
            loading: false,
            requestCount: 0,
            timeout: null,
            selectedContacts: null
        }
    },
    created() {
        this.getContacts()
    },
    computed: {
        contacts: {
            get: function () {
                const contacts = this.$store.getters['chat/getContacts'];

                if(!this.filters.order_by && this.filters.order_by === 'last_message') {
                    contacts.sort((a, b) => {
                        a = a.last_message != null ? a.last_message.time : 0;
                        b = b.last_message != null ? b.last_message.time : 0;
                        return b - a ;
                    });
                }
                return contacts;
            },
            set: function (val) {
                return this.$store.commit['chat/SET_CONTACTS', val];
            }
        },
    },
    watch: {
        filters: {
            handler: function() {
                clearTimeout(this.timeout);
                this.timeout = setTimeout(this.getContacts, 500);
            },
            deep: true
        },
        order: {
            handler: function() {
                clearTimeout(this.timeout);
                this.timeout = setTimeout(this.getContacts, 500);
            },
            deep: true
        },
    },
    methods: {
        scrollToTop() {
            const container = document.querySelector('#chat-scrollbar');
            if (container)
                container.scrollTop = 0;
        },
        forceOrder() {
            if(!this.filters.order_by || this.filters.order_by === 'message') {
                this.contacts.sort((a, b) => {
                    a = a.last_message != null ? a.last_message.time : 0;
                    b = b.last_message != null ? b.last_message.time : 0;
                    return b - a ;
                });
            }
        },
        parseFilters() {
            if(this.filters.filtering) {
                return {
                    ...this.order,
                    score: this.filters.score,
                    only_unreaded: this.filters.onlyUnreaded ? 1 : 0,
                    marks: this.filters.marks.map(e => e.key),
                    tags: this.filters.tags,
                    phones: this.filters.phones.map(e => e.key),
                    status: this.filters.status.map(e => e.key),
                    campaigns: this.filters.campaigns.map(e => e.key),
                };
            }

            return {
                ...this.order,
                search: this.filters.search,
                status: this.filters.status.map(e => e.key),
            };
        },
        getContacts(page = 1) {
            if(this.loading && page > 1)
                return;

            const params = { page, ...this.parseFilters(), limit: 30 };
            if(page === 1) 
                this.scrollToTop();

            this.loading = true;
            api.get('/chats', { params })
            .then((res) => {
                if (this.requestCount > 1) return;
                const { message, type, body, misc } = res.data;

                if(type === 'success') {
                    this.pagination = misc;
                    if(page > 1)
                        return this.$store.commit('chat/SET_CONTACTS', [...this.contacts, ...body]);
                        
                    this.$store.commit('chat/SET_CONTACTS', body);
                }

                throw message;
            })
            .catch(() => {
                if (this.requestCount > 1)
                    return;
                this.contacts = []
            })
            .finally(() => {
                this.requestCount -= 1;
                if (this.requestCount >= 1)
                    return;
                this.loading = false;
            })

            this.requestCount += 1;
        },
        loadMore({ target: { scrollTop, clientHeight, scrollHeight }}) {
            if (!(scrollTop + clientHeight >= scrollHeight - 800)) 
                return;
            if(this.pagination === null || this.pagination.last_page <= this.pagination.current_page) 
                return;
            if(this.loading) 
                return;

            this.getContacts(this.pagination.current_page + 1);
        },
        toggleContact(contact) {
            const index = this.selectedContacts.findIndex(e => e === contact);

            if(index >= 0)
                return this.selectedContacts.splice(index, 1);

            this.selectedContacts.push(contact);
        },
        toogleChat(){
            const sidebar = document.querySelector('.sidebar-container');
            if(sidebar.classList.contains('show')) {
                return sidebar.classList.remove('show');
            }
        }
    },
    props: {
        isMobileLarge: {
            type: String,
            default: ''
        },
        isMobileSmall: {
            type: Boolean,
            default: false
        },
    }
};
</script>

<style lang="scss"> 

.status {
    margin-left: 35px;
}

.button, .button:hover {
    color: #0d6efd;
    border-color: #0d6efd;
}

.results {
    display: flex;
    align-items: center;
}

.results hr {
    background: #EEEEEE;
    flex: 1;
    margin-left: 10px;
    height: 2px;
    border: none;
}

.right-button {
    margin-left: 50%;
}

svg:hover {
    cursor: pointer;
}

.contacts-move,
.contacts-enter-active,
.contacts-leave-active {
  transition: all 0.8s ease-in-out;
}

.contacts-enter,
.contacts-leave-to {
  opacity: 0;
  transform: translateX(80px);
}

.sidebar-container {
    .ps__rail-x { 
        display: none !important; 
    }
}
</style>
