<template>
    <section :class="isMobileSmall ? 'p-2' : 'p-3'">
        <div class="d-flex align-items-center flex-nowrap" :class="isMobileSmall ? 'mobile' : ''">
            <div v-if="!history" class="input-group d-flex flex-nowrap flex-grow-1 m-0">
                <div class="input-group-prepend">
                    <span class="input-group-text">
                        <font-awesome-icon icon="fa-solid fa-magnifying-glass" style="cursor: default;"/>
                    </span>
                </div>

                <span
                    v-if="filters.filtering"
                    class="form-control text-left text-blue flex-grow-1 bg-white cursor-pointer"
                    role="button"
                    @click="showFilters = !showFilters"
                >
                    Alterar filtros
                </span>

                <input
                    v-else-if="!history"
                    v-model="filters.search"
                    :placeholder="showFilters ? 'Adicionar Tags' : 'Pesquisar por uma conversa'"
                    aria-label="Pesquisar"
                    class="form-control"
                    @keyup.enter="() => addTag()"
                >
            </div>
            <div v-if="history" class="d-flex align-items-center w-100 justify-content-start">
                <span @click="() => applyHistory(false)" role="button" class="mx-3">
                    <font-awesome-icon icon="fa-solid fa-chevron-left" class="text-blue" size="lg"/>
                </span>
                <h5
                    class="text-blue font-weight-bold m-0"
                >
                    Histórico
                </h5>
            </div>

            <span v-if="!filters.filtering" class="btn ml-1" :class="[isMobileSmall ? 'px-0' : 'p-2']" @click="showFilters = !showFilters">
                <feather-icon
                    :class="{'text-blue': showFilters, 'text-secondary': !showFilters}"
                    class="ml-1 btn-icon"
                    icon="FilterIcon"
                    variant="flat-success"
                />
            </span>

            <button v-else class="btn btn-primary mx-2" @click="clear">Limpar</button>

            <b-dropdown class="mr-0 p-n2" dropleft no-caret toggle-class="shadow-none" variant="link">
                <template #button-content>
                    <feather-icon class="text-secondary" icon="MoreVerticalIcon"/>
                </template>
                <b-dropdown-item @click="() => applyHistory(true)">Historico</b-dropdown-item>
                <b-dropdown-item @click="$emit('select', true)">Selecionar</b-dropdown-item>
                <b-dropdown-item v-b-modal.orderModal>Ordenar</b-dropdown-item>
            </b-dropdown>
        </div>

        <section v-if="showFilters" class="my-3">
            <vue-perfect-scrollbar
                :settings="{ suppressScrollX  : true }"
                style="max-height: 60vh !important;"
            >
                <filter-tags v-model="filters.tags"/>

                <div class="d-flex justify-content-start align-items-center">
                    <label class="font-weight-bold mr-1 text-secondary m-0">Score</label>
                    <b-form-rating
                        :show-clear="filters.score != null"
                        v-model="filters.score"
                        class="text-center shadow-none"
                        inline
                        no-border
                    ></b-form-rating>
                </div>

                <div class="d-flex justify-content-start align-items-center mb-1">
                    <label for="filter-unreaded" class="font-weight-bold mr-3 text-secondary m-0">Apenas mensagens não lidas</label>
                    
                    <b-form-checkbox id="filter-unreaded" v-model="filters.onlyUnreaded" name="check-button" switch>
                    </b-form-checkbox>
                </div>

                <section class="d-flex flex-column align-items-start">
                    <a role="button" class="text-secondary text-decoration-none my-1" id="popover-filter-status" tabindex="-1">
                        <b class="mr-2">Status da Conversa</b>
                        <span v-if="loading.status" class="spinner-border spinner-border-sm"
                                role="status"></span>
                        <font-awesome-icon v-else icon="fa-solid fa-chevron-down" size="sm"/>
                    </a>
                    <pop-dropdown
                        :items="filtersOptions.status.filter(e => !filters.status.includes(e))"
                        target="popover-filter-status"
                    >
                        <template #item="{ data: { item, index } }">
                            <a
                                class="w-100 p-2 text-decoration-none"
                                role="button"
                                custom-class="p-0"
                                :key="`popover-dropdown-item-${index}`"
                                @click.prevent="filters.status.push(item)"
                            >
                                {{ item.text }}
                            </a>
                        </template>
                    </pop-dropdown>
                    <filter-tags v-model="filters.status" key-label="text"/>
                </section>

                <div class="d-flex flex-column align-items-start">
                    <a  role="button" class="text-secondary text-decoration-none my-1" id="popover-filter-marks" tabindex="-1">
                        <b class="mr-2">Marcador</b>
                        <span v-if="loading.marks" class="spinner-border spinner-border-sm"
                                role="status"></span>
                        <font-awesome-icon v-else icon="fa-solid fa-chevron-down" size="sm"/>
                    </a>
                    <pop-dropdown
                        :items="filtersOptions.marks.filter(e => !filters.marks.includes(e))"
                        target="popover-filter-marks"
                    >
                        <template #item="{ data: { item, index } }">
                            <a
                                class="w-100 p-2 text-decoration-none"
                                role="button"
                                custom-class="p-0"
                                :key="`popover-dropdown-item-${index}`"
                                @click.prevent="filters.marks.push(item)"
                            >
                                {{ item.text }}
                            </a>
                        </template>
                    </pop-dropdown>
                    <filter-tags v-model="filters.marks" key-label="text"/>
                </div>

                <section class="d-flex flex-column align-items-start">
                    <a role="button" class="text-secondary text-decoration-none my-1" id="popover-filter-phones" tabindex="-1">
                        <b class="mr-2">Número de Disparo</b>
                        <span v-if="loading.phones" class="spinner-border spinner-border-sm"
                                role="status"></span>
                        <font-awesome-icon v-else icon="fa-solid fa-chevron-down" size="sm"/>
                    </a>
                    <pop-dropdown
                        :items="filtersOptions.phones.filter(e => !filters.phones.includes(e))"
                        target="popover-filter-phones"
                    >
                        <template #item="{ data: { item, index } }">
                            <a
                                class="w-100 p-2 text-decoration-none"
                                role="button"
                                custom-class="p-0"
                                :key="`popover-dropdown-item-${index}`"
                                @click.prevent="filters.phones.push(item)"
                            >
                                {{ item.text }}
                            </a>
                        </template>
                    </pop-dropdown>
                    <filter-tags v-model="filters.phones" key-label="text"/>
                </section>

                <section v-if="user.can_shot" class="d-flex flex-column align-items-start">
                    <a role="button" class="text-secondary text-decoration-none my-1" id="popover-filter-campaign" tabindex="-1">
                        <b class="mr-2">Campanha</b>
                        <span v-if="loading.campaigns" class="spinner-border spinner-border-sm"
                                role="status"></span>
                        <font-awesome-icon v-else icon="fa-solid fa-chevron-down" size="sm"/>
                    </a>
                    <pop-dropdown
                        :items="filtersOptions.campaigns.filter(e => !filters.campaigns.includes(e))"
                        target="popover-filter-campaign"
                    >
                        <template #item="{ data: { item, index } }">
                            <a
                                class="w-100 p-2 text-decoration-none"
                                role="button"
                                custom-class="p-0"
                                :key="`popover-dropdown-item-${index}`"
                                @click.prevent="filters.campaigns.push(item)"
                            >
                                {{ item.text }}
                            </a>
                        </template>
                    </pop-dropdown>
                    <filter-tags v-model="filters.campaigns" key-label="text"/>
                </section>
            </vue-perfect-scrollbar>

            <section class="d-flex align-items-center py-2" :class="!isMobileSmall ? 'justify-content-end' : 'justify-content-start'">
                <button class="btn btn-outline-secondary mr-2" type="button" @click="clear">
                    Limpar
                </button>
                <button class="btn btn-primary" type="button" @click="apply">
                    Aplicar
                </button>
            </section>
        </section>
    </section>
</template>

<script>
import {
    BFormRating,
    BDropdown,
    BDropdownItem,
    BFormCheckbox
} from "bootstrap-vue";
import FilterTags from "@/views/pages/dashboard/messages/components/FilterTags";
import PopDropdown from "@/views/pages/dashboard/messages/components/PopDropdown";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import {filtersModel, chatTypes} from '../constants';
import api from "@/api";

export default {
    components: {
        BFormRating,
        BDropdown,
        BDropdownItem,
        BFormCheckbox,
        FilterTags,
        VuePerfectScrollbar,
        PopDropdown
    },
    emits: ['input', 'select'],
    props: {
        value: {
            type: Object,
            default: () => {
                return Object.assign({}, filtersModel);
            }
        },
        isMobileSmall: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            showFilters: false,
            filters: JSON.parse(JSON.stringify(this.$props.value)),
            filtersOptions: {
                status: chatTypes.map(e => {
                    return {text: e.label, key: e.id}
                }),
                marks: [],
                dates: [
                    {text: 'Últimos 6 meses', key: 'teste'}
                ],
                phones: [],
                campaigns: [],
                onlyUnreaded: false
            },
            history: false,
            loading: {
                marks: false,
                campaigns: false,
                phones: false,
            }
        }
    },
    watch: {
        'value': {
            handler: function () {
                this.filters = JSON.parse(JSON.stringify(this.$props.value));
            },
            deep: true
        },
        'filters.search'(val) {
            if (!this.showFilters) {
                const filters = filtersModel;
                filters.search = val;
                this.$emit('input', Object.assign({}, filters));
            }
        },
        'showFilters'(val) {
            if(val) {
                if(this.filtersOptions.marks.length == 0)
                    this.getMarks();
                if(this.filtersOptions.campaigns.length == 0)
                    this.getCampaigns();
                if(this.filtersOptions.phones.length == 0)
                    this.getPhones();
            }
        }
    },
    methods: {
        clear() {
            this.$emit('input', Object.assign({}, filtersModel));
        },
        applyHistory(status) {
            this.history = status;
            const filters = { ...filtersModel, status: status ? [{key: 'finished'}] : [] }
            this.$emit('input', filters);
        },
        apply() {
            this.filters.filtering = true;
            this.showFilters = false;
            this.$emit('input', this.filters);
        },
        addTag() {
            if (this.showFilters) {
                this.filters.tags.push(this.filters.search);
                this.filters.search = '';
            }
        },
        getResource(route, query = {}) {
            return api.get(route, {params: query})
                .then(res => {
                    const {type, body, message} = res.data;

                    if (type === 'success') {
                        return body;
                    }
                    throw message;
                })
                .catch(error => {
                    console.log(error);
                    return [];
                })
        },
        getMarks() {
            this.loading.marks = true;
            this.getResource('/marks')
                .then(marks => {
                    this.filtersOptions.marks = marks.map(e => {
                        return {
                            text: e.name,
                            key: e._id
                        }
                    });
                })
                .finally(() => this.loading.marks = false)
        },
        getCampaigns() {
            this.loading.campaigns = true;
            this.getResource('/campaigns', {all: 1, only: ['_id', 'name']})
                .then(campaigns => {
                    this.filtersOptions.campaigns = campaigns.map(e => {
                        return {
                            text: e.name,
                            key: e._id
                        }
                    });
                })
                .finally(() => this.loading.campaigns = false)
        },
        getPhones() {
            this.loading.phones = true;
            this.getResource('/phones')
                .then(phones => {
                    this.filtersOptions.phones = phones.map(e => {
                        return {
                            text: e.phone.replace(/(\d{2})(\d{2})(\d{1,})(\d{4})/, "+$1 ($2) $3-$4"),
                            key: e._id
                        }
                    });
                })
                .finally(() => this.loading.phones = false)
        }
    }
};
</script>

<style lang="scss">
.scroll-area {
    position: relative;
    margin: auto;
    height: 60rem;
}

.scroll-area-two {
    position: relative;
    margin: auto;
    height: 16rem;
}

.long-message {
    max-width: 30ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.scroll-dropdown {
    max-height: 200px;
    overflow-y: auto;
}

.mobile {
    @media screen and (max-width: 374px) {
        max-width: 265px;
    }
    
    @media screen and (min-width: 375px) and (max-width: 424px) {
        max-width: 315px;
    }
}
</style>
