<template>
    <b-modal
        id="commentsModal"
        body-class="position-unset"
        centered
        content-class="p-4 position-relative"
        footer-border-variant="light"
        header-border-variant="light"
        hide-footer
        title="Comentários"
        size="lg"
        lazy
        @show="onShow"
    >
        <loading v-if="loading" />
        
        <p v-if="comments.length == 0 && !loading">Nenhum comentário encontrado</p>
        <div v-else class="mb-4 chat-app-window position-relative">
            <vue-perfect-scrollbar class="user-chats scroll-area px-0" style="max-height: 250px;">
                <div class="chats">
                    <div class="chat">
                        <div
                            v-for="(comment, i) in comments"
                            :key="`comment-log-${i}-${comment._id}`"
                            class="chat-line left mb-3"
                        >
                            <div class="chat-content">
                                <div class="chat-bookmark">
                                    <font-awesome-icon
                                        class="text-blue mr-2"
                                        icon="fa-solid fa-edit"
                                        @click="() => Object.assign(form, comment)"
                                        v-b-tooltip="'Editar'"
                                    />
                                    <font-awesome-icon
                                        class="text-blue"
                                        icon="fa-solid fa-circle-xmark"
                                        @click="() => remove(comment._id)"
                                        v-b-tooltip="'Remover'"
                                    />

                                </div>
                                <p class="text-justify font-weight-normal">
                                    <span>{{ comment.comment }}</span>
                                </p>
                            </div>
                            <small class="chat-time">{{ getStringTime(comment.created_at) }}</small>
                        </div>
                    </div>
                </div>
            </vue-perfect-scrollbar>
        </div>

        <validation-observer ref="commentForm">
            <validation-provider
                #default="{ failed }"
                rules="required"
            >
                <textarea
                    class="form-control"
                    :class="{'is-invalid': failed}"
                    cols="30" 
                    rows="5"
                    placeholder="Escreva um comentário sobre o cliente"
                    v-model="form.comment"
                ></textarea>
                <button
                    @click="submit"
                    class="btn btn-primary mb-4 mt-2 mr-2"
                >
                    {{ form._id == null ? 'Adicionar' : 'Editar' }} comentário
                </button>
                <button
                    @click="reset"
                    class="btn btn-secondary mb-4 mt-2"
                    v-if="form._id != null"
                >
                    Cancelar
                </button>
            </validation-provider>
        </validation-observer>
    </b-modal>
</template>

<script>
import {BModal} from "bootstrap-vue";
import {getStringTime} from '@/api/functions';
import Loading from '@/views/components/Loading.vue';
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import api from "@/api";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "@/@core/utils/validations/validations";

export default {
    components: {
        BModal,
        Loading,
        VuePerfectScrollbar,
        ValidationObserver,
        ValidationProvider
    },
    props: ['customer_id'],
    data() {
        return {
            form: {
                _id: null,
                comment: null
            },
            comments: [],
            required,
            loading: false
        };
    },
    methods: {
        getStringTime,
        onShow() {
            this.getComments();
        },
        toast(message, type = 'danger') {
            if (type == 'error') type = 'danger';
            this.$bvToast.toast(message, {title: 'Comentários', variant: type})
        },
        getComments() {
            this.loading = true;
            
            api.get(`/customers/${this.customer_id}/comments`)
                .then(response => {
                    const {type, body} = response.data;

                    if (type == 'success') {
                        return this.comments = body;
                    }
                    throw response;
                })
                .catch((error) => {
                    console.log(`getComments: ${error}`);
                    this.toast('Não foi possível carregar comentários.')
                    this.comments = [];
                })
                .finally(() => this.loading = false);
        },
        reset() {
            this.form = {
                _id: null,
                comment: null
            }
        },
        submit() {
            this.$refs.commentForm.validate().then(success => {
                if(!success) return;

                this.loading = true;

                const id = this.form._id;
                api({
                    url: id ? `/customers/${this.customer_id}/comments/${id}` : `/customers/${this.customer_id}/comments/`,
                    method: id ? 'PUT' : 'POST',
                    data: this.form
                }, this.form)
                    .then(response => {
                        const {type, body, message} = response.data;

                        if (type == 'success') {
                            this.reset();
                            this.toast(message, 'success')

                            if(id != null) {
                                const index = this.comments.findIndex(e => e._id == body._id);
                                return this.comments.splice(index, 1, body);
                            }
                            return this.comments.splice(0, 0, body);
                        }
                        throw response;
                    })
                    .catch((error) => {
                        let message = 'Não foi possível adicionar comentários.';

                        if(error.response) message = error.response.data.message;

                        this.toast(message)
                    })
                    .finally(() => this.loading = false);
            })
        },
        remove(id) {
            this.$swal({
                title: 'Tem certeza?',
                text: 'Deseja remover esse comentário?',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Remover',
                confirmButtonColor: "#317f98",
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return api.delete(`/customers/${this.customer_id}/comments/${id}`);
                }
            })
                .then((result) => {
                    if (result.isConfirmed && result.value.data.type) {
                        const {type, message} = result.value.data;

                        if (type == 'success') {
                            const index = this.comments.findIndex(e => e._id == id);
                            if (index >= 0)
                                this.comments.splice(index, 1);
                        }
                        this.toast(message, type);
                    }
                })
                .catch((error) => {
                    let message = 'Não foi possível remover comentário.';

                    if(error.response) message = error.response.data.message;

                    this.toast(message)
                })
        },
    },
}
</script>

<style scoped lang="scss">
.user-chats {
    scroll-behavior: unset !important;
}
.chat-bookmark {
    top: 8px !important;
    right: 10px !important;
    opacity: 0;
    transition: 300ms;
}

.chat-content:hover .chat-bookmark {
    opacity: 1;
}
</style>