<template>
    <b-modal
        id="orderModal"
        title="Ordenar"
        centered
        size="lg"
        header-border-variant="light"
        footer-border-variant="light"
        content-class="p-4 position-relative"
        body-class="position-unset"
        @show="reset"
    >
        <div class="row">
            <div class="col-auto">
                <b-form-select v-model="data.order_by" :options="orderColumnOptions" size="lg">
                </b-form-select>
            </div>
            <div :class="orderByValue ? 'col-5' : 'col-auto'">
                <b-form-select v-if="orderByValue" :disabled="!data.order_by" v-model="data.order_with" :options="orderOptions" size="lg"></b-form-select>
                <b-form-select v-else :disabled="!data.order_by" v-model="data.order_as" :options="orderOptions" size="lg"></b-form-select>
            </div>
        </div>

        <template #modal-footer="{ cancel, ok }">
            <button @click="cancel" class="btn btn-outline-primary px-5">
                Cancelar
            </button>
            <button @click="() => {$emit('input', data); ok();}" class="btn btn-primary px-5">
                Aplicar
            </button>
        </template>
    </b-modal>
</template>

<script>
import {BModal, BFormSelect} from "bootstrap-vue";
import api from "@/api";

const options = {
    message: [
        {value: null, text: 'Selecione o critério', disabled: true},
        {value: 1, text: 'Mais novos'},
        {value: -1, text: 'Mais antigos'},
    ],
    status: [
        {value: null, text: 'Selecione o status', disabled: true},
        {value: 'active', text: 'Manter Conversa Ativa'},
        {value: 'finished', text: 'Atendimento Finalizado'},
        {value: 'pending', text: 'Conversa Pendente'},
        {value: 'supervisor', text: 'Enviar Para o Supervisor'},
    ],
    campaign: [{value: null, text: "Selecione uma opção", disabled: true}]
}

export default {
    props: {
        value: {
            type: Object
        }
    },
    emits: ['input'],
    components: {
        BModal,
        BFormSelect,
    },
    watch: {
        "data.order_by"(val) {
            this.getCampaigns();
            if(val == null)
                return this.orderOptions = [{value: 'null', text: 'Selecione o critério', disabled: true}];

            this.orderOptions = options[val];
        }
    },
    data() {
        return {
            data: Object.assign({}, this.$props.value),
            orderColumnOptions: [
                {value: null, text: 'Selecione uma opção', disabled: true},
                {value: 'message', text: 'Ordem de recebimento'},
                {value: 'campaign', text: 'Campanhas'},
                {value: 'status', text: 'Status da Conversa'},
            ],
            orderOptions: [
                {value: null, text: 'Selecione o critério', disabled: true},
            ],
        };
    },

    computed: {
        orderByValue() {
            return this.data.order_by === 'campaign' || this.data.order_by === 'status';
        }
    },

    methods: {
        reset() {
            this.data = Object.assign({}, this.$props.value);
        },

        getCampaigns() {
           api.get('/campaigns', { params: { all:  1 } })
                .then(response => {
                    const {body, type} = response.data;
                    if (type !== 'success') return

                    let campaigns = body.reduce((acc, campaign) => {
                        acc.push({
                            value: campaign._id,
                            text: campaign.name,
                            disabled: false
                        });
                        return acc;
                    }, []);

                    options['campaign'].push(...campaigns);
                })
        },

        getOptions(value) {
            if (value === 'campaign') {
                return this.getCampaigns();
            }

            return options[value];
        }
    }
}
</script>

<style>
</style>
