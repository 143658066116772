<template>
    <div class="w-100 d-flex flex-grow-1 position-relative">
        <loading v-if="loading" class="flex-grow-1" />

        <section class="active-chat flex-grow-1 w-100 d-flex flex-column" v-if="chat != null">
            <!-- Chat Navbar -->
            <div  class="chat-navbar">
                <header class="chat-header flex-wrap">
                    <!-- Avatar & Name -->
                    <div class="d-flex align-items-center py-2 px-4">
                        <toggle-sidebar-button class="mr-4" />

                        <b-avatar
                            size="40"
                            :src="chat.profile_img"
                            onerror="this.src=/assets/img/default-user.jpg"
                            class="mr-3 cursor-pointer"
                        />
                        <div class="d-flex align-items-start flex-column">
                            <div class="d-flex">
                                <h6 class="mb-0 text-left mr-2 text-black my-auto text-nowrap">
                                    {{ name }}
                                </h6>
                                <b-link
                                    class="text-gray"
                                    v-if="chat.customer != null"
                                    v-b-modal.customerDataModal
                                >
                                    <feather-icon
                                        icon="InfoIcon"
                                        class="cursor-pointer"
                                        size="21"
                                    />
                                </b-link>
                            </div>

                            <small v-if="chat.name != chat.phone && chat.customer == null">~ {{ chat.name }}</small>

                            <div class="item-rating" v-if="chat.customer != null && chat.customer.score != null">
                                <ul class="unstyled-list list-inline" >
                                    <li
                                        v-for="star in 5"
                                        :key="star"
                                        class="ratings-list-item"
                                    >
                                        <feather-icon
                                            icon="StarIcon"
                                            size="16"
                                            color="warning"
                                            :class="[
                                                { 'fill-current': star <= Number(chat.customer.score) },
                                                star <= Number(chat.customer.score) ? 'text-blue' : 'text-muted',
                                            ]"
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- Contact Actions -->
                    <div
                        class="d-flex align-items-center contact-actions py-3 px-4 flex-grow-1" :class="'justify-content-end'"
                    >
                        <chat-icon
                            :iconName="chat.customer == null ? 'icon-1' : 'icon-6'"
                            v-b-modal.saveCustomerModal
                            class="mx-2"
                            v-b-tooltip.hover="chat.customer == null ? 'Adicionar Cliente' : 'Editar Cliente'"
                            v-if="chat.customer == null ? $role('customers.create') : $role('customers.update')"
                        />
                        <chat-icon
                            iconName="icon-2"
                            v-b-modal.transferChatModal
                            class="mx-2"
                            v-b-tooltip.hover="'Transferir Chat'"
                            :iconColor="isTransfer() ? '#0076FF' : '#999999'"
                            v-if="!chat.is_blocked"
                        />
                        <chat-icon
                            iconName="icon-3"
                            v-b-toggle.productsSidebar
                            class="mx-2"
                            v-b-tooltip.hover="'Produtos'"
                            v-if="!chat.is_blocked"
                        />
                        <chat-icon
                            iconName="icon-5"
                            class="mx-2"
                            v-b-tooltip.hover="'Bloquear'"
                            :iconColor="chat.is_blocked ? '#0076FF' : '#999999'"
                            @click.prevent.native="blockChat"
                            v-if="$role('chats.block')"
                        />
                        <chat-icon
                            iconName="icon-11"
                            class="mx-2"
                            v-b-tooltip.hover="isCampaignBlocked ? 'Desbloquear disparo de campanhas' : 'Bloquear disparo de campanhas'"
                            :iconColor="isCampaignBlocked ? '#0076FF' : '#999999'"
                            @click.prevent.native="blockCampaign"
                            v-if="$role('chats.block') && chat.customer"
                        />
                        <chat-icon
                            iconName="icon-7"
                            :value="chat.customer"
                            v-b-toggle.marksSidebar
                            class="mx-2"
                            v-b-tooltip.hover="'Marcadores'"
                            v-if="!chat.is_blocked && $role('chats.update')"
                        />
                        <chat-icon
                            iconName="icon-8"
                            id="pauseMessagesPopover"
                            ref="button"
                            class="mx-2"
                            v-b-tooltip.hover="'Pausar Mensagens'"
                            :iconColor="isPaused() ? '#0076FF' : '#999999'"
                            v-if="!chat.is_blocked"
                        />
                        <chat-icon
                            iconName="icon-10"
                            class="mx-2"
                            v-b-tooltip.hover="'Comentários'"
                            v-if="chat.customer != null"
                            v-b-modal.commentsModal
                        />
                        <chat-icon
                            iconName="icon-9"
                            class="mx-2"
                            v-b-tooltip.hover="'Pesquisar Mensagem'"
                            v-b-toggle.searchMessageSidebar
                        />
                    </div>
                </header>
            </div>

            <!-- User Chat Area -->
            <chat-log
                v-if="chat != null"
                :chat="chat"
                @updateCustomer="customer => Object.assign(chat, { customer })"
            />
            <p class="m-0" v-if="chat == null & !loading">Envie uma mensagem para começar a conversa</p>

            <chat-input v-if="!isPaused() && !chat.is_blocked" :chat="chat"/>

            <comments-modal v-if="chat.customer != null" :customer_id="chat.customer._id"/>
            <customer-data-modal v-if="chat.customer != null" :contact="chat"/>
            <save-customer-modal v-if="chat.customer == null && $role('customers.create') || chat.customer != null && $role('customers.update')" v-model="chat"/>
            <transfer-chat-modal v-model="chat"/>
            <products-sidebar :chat="chat"/>
            <marks-sidebar v-if="$role('chats.update')" v-model="chat"/>
            <quick-answers-sidebar v-if="chat != null" :contact-id="chat._id"/>
            <search-message-sidebar :contact="chat"/>
            <pause-messages-popup v-model="chat"/>
        </section>
    </div>
</template>

<script>
import {
    BAvatar,
    BLink,
} from "bootstrap-vue";
import ChatLog from "./ChatLog.vue";
import CustomerDataModal from "@/views/pages/dashboard/messages/modals/CustomerDataModal.vue"
import SaveCustomerModal from "@/views/pages/dashboard/messages/modals/SaveCustomerModal"
import TransferChatModal from "@/views/pages/dashboard/messages/modals/TransferChatModal.vue"
import ProductsSidebar from "@/views/pages/dashboard/messages/modals/ProductsSidebar.vue"
import PauseMessagesPopup from "@/views/pages/dashboard/messages/components/PauseMessagesPopup.vue"
import MarksSidebar from "@/views/pages/dashboard/messages/modals/MarksSidebar.vue";
import QuickAnswersSidebar from "@/views/pages/dashboard/messages/modals/QuickAnswersSidebar.vue";
import SearchMessageSidebar from "@/views/pages/dashboard/messages/modals/SearchMessageSidebar.vue";
import ToggleSidebarButton from "@/views/pages/dashboard/messages/components/ToggleSidebarButton.vue";
import Loading from "@/views/components/Loading.vue";
import ChatIcon from "@/views/pages/dashboard/messages/icons/ChatIcon.vue";
import ChatInput from "@/views/pages/dashboard/messages/ChatInput.vue";
import api from "@/api";
import { formatPhone } from "@/api/functions"
import { setScrollMessageId } from './functions';
import CommentsModal from "./modals/CommentsModal.vue";
import moment from "moment";

export default {
    data() {
        return {
            chat: null,
            loading: false,
            isCampaignBlocked: null
        }
    },
    components: {
        BAvatar,
        ChatIcon,
        ToggleSidebarButton,
        BLink,
        ChatInput,
        Loading,
        ChatLog,
        CustomerDataModal,
        SaveCustomerModal,
        SearchMessageSidebar,
        QuickAnswersSidebar,
        TransferChatModal,
        ProductsSidebar,
        PauseMessagesPopup,
        MarksSidebar,
        CommentsModal
    },
    props: {
        phone: {
            type: String,
            required: true
        },
        isMobileSmall: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        phone(val) {
            this.chat = null;
            this.getChat(val);
        },
        chat: {
            handler: function(val) {
                if(val == null) return;
                this.$store.commit('chat/SET_CONTACT', val);
                this.$forceUpdate();
            },
            deep: true
        },
        '$store.state.chat': {
            handler(val) {
                const contact = val.contacts.find(e => e.phone == this.phone);
                if(contact != null && this.chat != null)
                    Object.assign(this.chat, contact);
            },
            deep: true
        },
        '$route.query.message'(val) {
            if(val == null || this.chat == null) return;
            this.$forceUpdate();
            setTimeout(() => {
                setScrollMessageId(val).then(() => {
                    this.$router.replace({ ...this.$route, query: {} });
                })
            }, 50);
        }
    },
    computed: {
        name() {
            if (this.chat.customer)
                return this.chat.customer.name;

            const phone =  this.chat.phone;
            
            return formatPhone(phone);
        }
    },
    created() {
        this.getChat(this.$props.phone);
    },
    methods: {
        toast(message, type = 'danger') {
            if (type === 'error') type = 'danger';
            this.$bvToast.toast(message, {title: 'Respostas', variant: type})
        },
        getChat(phone) {
            this.loading = true;
            api.get(`/chats/${phone}`)
                .then(res => {
                    const { type, body, message } = res.data;
                    if(type === 'success') {
                        this.isCampaignBlocked = body.customer && body.customer.is_campaign_blocked ? body.customer.is_campaign_blocked : null;
                        return this.chat = body;
                    }
                    throw message;
                })
                .catch(e => {
                    console.log(e);
                    this.toast('Não foi possível carregar o chat.');
                    this.$router.replace({ name: 'chats' });
                })
                .finally(() => this.loading = false);
        },
        onClickLinkChild(value) {
            this.show = value;
        },
        isPaused() {
            if(this.chat && this.chat.paused_until) {
                const date = moment(this.chat.paused_until);
                return !(isNaN(date) || date.isSameOrBefore(moment()))
            }
            return false;
        },
        isTransfer() {
            return this.chat && this.chat.transfer;
        },
        blockChat() {
            const is_blocked = Boolean(this.chat.is_blocked);
            this.$swal({
                title: is_blocked
                    ? 'Tem certeza que deseja desbloquear este contato?'
                    : 'Tem certeza que deseja bloquear este contato?',
                text:  is_blocked
                    ? 'As mensagens voltarão a ser recebidas. O contato não será desbloqueado nas redes.'
                    : 'As futuras mensagens com este contato serão ignoradas. O contato não será bloqueado nas redes.',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: is_blocked ? 'Desbloquear' : 'Bloquear',
                confirmButtonColor: "#317f98",
                reverseButtons: true,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return api.put(`/chats/${this.chat._id}`, { is_blocked: !is_blocked });
                }
            })
                .then((result) => {
                    if (result.isConfirmed && result.value.data.type) {
                        const { type, body } = result.value.data;

                        if(type === 'success') {
                            return this.chat = body;
                        }
                        throw result.value.data;
                    }
                })
                .catch((error) => {
                    console.log(`blockChat: ${error}`);
                    this.$bvToast.toast('Não foi possível bloquear contato.', {title: 'Bloquear', variant: 'danger'});
                })
        },
        blockCampaign() {
            const id = this.chat.customer._id;
            const is_campaign_blocked = Boolean(this.isCampaignBlocked);
            this.$swal({
                title: `Tem certeza que deseja ${is_campaign_blocked ? 'desbloquear' : 'bloquear'} o envio de campanhas para este contato?`,
                text: is_campaign_blocked 
                    ? 'As campanhas voltarão a ser enviadas para o contato.'
                    : 'As futuras campanhas não serão enviadas para o contato.',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: is_campaign_blocked ? 'Desbloquear' : 'Bloquear',
                confirmButtonColor: "#317f98",
                reverseButtons: true,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return api.put(`/customers/${id}`, {
                        is_campaign_blocked: !is_campaign_blocked, 
                        phone_id: this.chat.customer.phone_id 
                    });
                }
            })
                .then((result) => {
                    if (result.isConfirmed && result.value.data.type) {
                        const { type, body } = result.value.data;

                        if (type === 'success') {
                            this.isCampaignBlocked = body.is_campaign_blocked
                            return this.chat.customer = body;
                        }
                        throw result.value.data;
                    }
                })
                .catch((error) => {
                    console.log(`blockCampaign: ${error}`);
                    this.$bvToast.toast('Não foi possível bloquear campanhas do contato.', {title: 'Bloquear campanhas', variant: 'danger'})
                })
        }
    },
};
</script>
