<template>
    <b-modal
        :id="id"
        :no-close-on-backdrop="loading"
        :no-close-on-esc="loading"
        :title="'Enviar mensagens em massa'"
        body-class="position-unset"
        centered
        content-class="p-4 position-relative"
        footer-border-variant="light"
        header-border-variant="light"
        lazy
        size="lg"
        hide-footer
    >
        <loading v-if="loading"/>

        <chat-input :chat="chats"/>
    </b-modal>
</template>

<script>
import {BModal} from "bootstrap-vue";
import Loading from "@/views/components/Loading.vue";
import ChatInput from "../ChatInput.vue";

export default {
    components: {
        BModal,
        Loading,
        ChatInput
    },
    props: {
        chats: {
            type: Array,
            default: () => []
        },
        id: {
            type: String,
            default: () => "sendMessageModal"
        }
    },
    data() {
        return {
            loading: false
        }
    }
}
</script>
