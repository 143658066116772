<template>
    <b-popover
        v-bind="$attrs"
        placement="bottomright"
        custom-class="popover-dropdown"
        triggers="focus"
        @hide="search = ''"
        variant="light"
    >
        <div class="p-2">
            <input
                type="text"
                class="form-control"
                placeholder="Pesquisar..."
                v-model="search"
            />
        </div>

        <div class="scrollable d-flex flex-column custom-scroll">
            <p class="m-2 text-center" v-if="filtered.length == 0">
                Nenhuma opção selecionável
            </p>
            <slot
                name="item"
                v-for="(item, i) in filtered"
                v-bind:data="{ item, index: i }"
            >
                <a
                    class="w-100 p-2 text-decoration-none"
                    role="button"
                    :key="`popover-dropdown-item-${i}`"
                >
                    {{ item.text }}
                </a>
            </slot>
        </div>
    </b-popover>
</template>

<script>
import { BPopover } from "bootstrap-vue";

export default {
    components: {
        BPopover,
    },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            search: "",
        };
    },
    computed: {
        filtered() {
            if (this.search == null || this.search == "")
                return this.$props.items;

            return this.$props.items.filter((e) =>
                String(e.text).toLowerCase().includes(this.search.toLowerCase())
            );
        },
    },
};
</script>

<style lang="scss">
.popover-dropdown {
    .scrollable {
        max-height: 250px;
        overflow: auto;
    }

    .custom-scroll {
        width: 250px;
    }

    .popover-body {
        padding: 0 !important;
    }

    a {
        min-width: 200px;
    }
}
</style>
