<template>
    <b-form
        class="chat-app-form flex-grow-1"
        @submit.prevent="sendMessage"
    >
        <div class="quillEditorContainer" :class="{ answer: quickAnswer != null }">
            <div v-if="quickAnswer != null" class="mx-4 d-flex flex-column">
                <div class="d-flex align-items-center justify-content-between text-revision">
                    <div>
                        <font-awesome-icon icon="fa-solid fa-circle-info" class="mr-2" />
                        <span>Esta resposta ainda precisa ser revisada.</span>
                    </div>
                    <span v-if="chat.suggest != null" @click="denyAnswer" role="button" class="text-danger">
                        <font-awesome-icon icon="fa-solid fa-trash-can" class="mr-2" />
                        <span>Recusar</span>
                    </span>
                </div>
                <div v-if="quickAnswer.attachment != null">
                    <div class="col-sm-2 col-lg-2 col-xl-1 px-0 mt-2">
                        <div class="attachment-container position-relative">
                            <font-awesome-icon
                                role="button"
                                color="red"
                                class="remove-attach"
                                icon="fa-solid fa-close"
                                @click="quickAnswer.attachment = null"
                            />
                            <img
                                class="img-thumbnail"
                                :src="quickAnswer.attachment.thumbnail"
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="quillEditor">
                <span
                    v-if="!isMass && !chat.suggest"
                    role="button"
                    v-b-toggle.quickAnswersSidebar
                >
                    <font-awesome-icon
                        class="text-blue"
                        icon="fa-solid fa-bolt-lightning"
                    />
                </span>
                <quill-editor
                    ref="refQuillEditor"
                    v-model="message"
                    :options="quillOptions"
                    toolbar="#chat-input-toolbar"
                    @paste.prevent="removeTagsOnPaste"
                >
                    <template #toolbar>
                        <div
                            class="chat-toolbar"
                        >
                            <div id="chat-input-toolbar"></div>
                            <b-dropdown
                                dropup
                                v-if="!isMass"
                                text="Atendimento Finalizado"
                                variant="flat-secondary"
                            >
                                <template #button-content>
                                    <span :class="'text-' + chatType.color">
                                        <font-awesome-icon
                                            :class="'text-' + chatType.color"
                                            :icon="'fa-solid ' + chatType.icon"
                                        />
                                        {{ chatType.label }}
                                    </span>
                                </template>
                                <b-dropdown-item
                                    v-for="chatType in chatTypes"
                                    :key="chatType.id"
                                    href="#"
                                    @click="() => changeStatus(chatType.id)"
                                >
                                    <font-awesome-icon
                                        :class="'text-' + chatType.color"
                                        :icon="'fa-solid ' + chatType.icon"
                                    />
                                    <span :class="'text-' + chatType.color">
                                        {{ chatType.label }}
                                    </span>
                                </b-dropdown-item>
                            </b-dropdown>

                            <b-button
                                class="send flex-grow-1"
                                type="submit"
                                variant="primary"
                                :disabled="message == null || message == ''"
                            >
                                <div v-if="loading">
                                    <span class="spinner-border spinner-border-sm" role="status"></span>
                                </div>
                                <div v-else>
                                    <span class="align-middle">
                                        {{ (quickAnswer != null || chat.suggest != null) ? 'Aceitar' : 'Enviar' }}
                                    </span>
                                    <font-awesome-icon class="text-white" icon="fa-solid fa-paper-plane"/>
                                </div>
                            </b-button>
                        </div>
                    </template>
                </quill-editor>
            </div>
        </div>
        <input
            :id="`${id}-file-input`"
            type="file"
            :accept="accept.map(e => `.${e}`).join(',')"
            hidden
            @change="onInputFile"
        >
        <b-modal
            :id="`${id}-file-preview-modal`"
            :visible="file != null && file.file != null"
            @hidden="file = null"
            title="Enviar mídia"
            class="position-relative"
        >
            <loading v-if="loading"></loading>
            <div
                v-if="file && file.file && file.file.type.includes('image')"
            >
                <vue-cropper
                    ref="cropper"
                    :src="src"
                ></vue-cropper>
            </div>
            <div
                v-if="file && file.file && file.file.type.includes('video')"
                class="embed-responsive embed-responsive-16by9"
            >
                <video
                    controls
                    :src="src"
                    class="embed-responsive-item"
                ></video>
            </div>
            <div
                v-if="file && file.file && file.file.type.includes('application/pdf')"
                class="embed-responsive embed-responsive-16by9"
            >
                <embed :src="src" type="application/pdf">
            </div>
            <div v-if="file && file.file && !file.file.type.includes('application/pdf')" class="input-group my-2">
                <input
                    type="text"
                    v-model="file.message"
                    class="form-control"
                    placeholder="Digite uma mensagem"
                >
                <span class="input-group-text">
                    <emoji @emoji="emoji => file.message += emoji" />
                </span>
            </div>
            <template #modal-footer="{ cancel }">
                <div class="d-flex align-items-center justify-content-end">
                    <button :disabled="loading" @click="cancel" class="btn btn-secondary mx-2">Cancelar</button>
                    <button :disabled="loading" @click="sendFile" class="btn btn-primary">Enviar</button>
                </div>
            </template>
        </b-modal>
        <short-link-modal />
    </b-form>
</template>

<script>
import api from "@/api";
import { getFaIconString } from "@/api/functions";
import { faFaceSmile } from "@fortawesome/free-solid-svg-icons";
import {
    BForm,
    BButton,
    BDropdown,
    BDropdownItem,
    BModal
} from "bootstrap-vue";
import {chatTypes} from './constants';
import ShortLinkModal from "./modals/ShortLinkModal.vue";
import Loading from '@/views/components/Loading';
import { acceptedMimes } from "@/api/constants";
import Emoji from '@/views/components/Emoji.vue';
import VueCropper from 'vue-cropperjs';
import { uuid } from "vue-uuid";

export default {
    components: {
        BForm,
        BButton,
        BDropdown,
        BDropdownItem,
        BModal,
        ShortLinkModal,
        Loading,
        Emoji,
        VueCropper
    },
    data() {
        return {
            message: this.chat.suggest == null ? null : this.chat.suggest.message,
            loading: false,
            chatTypes,
            quillOptions: {
                placeholder: "Digite sua mensagem",
                readonly: false,
                modules: {
                    clipboard: {
                        allowed: {
                            tags: ['strong', 's', 'em', 'p', 'br']
                        },
                        keepSelection: true,
                        magicPasteLinks: false,
                    },
                    toolbar: {
                        container: [
                            ["bold", "italic", "strike"],
                            ["image", "video", 'file', "link", "emoji"],
                        ],
                        handlers: {
                            link: () => {
                                this.$bvModal.show('shortLinkModal');
                            },
                            image: () => {
                                this.accept = ['jpg', 'jpeg', 'png']
                                if(this.chat.suggest)
                                    this.file = { message: this.chat.suggest.message };
                                setTimeout(() => document.getElementById(`${this.id}-file-input`).click(), 200);
                            },
                            file: () => {
                                this.accept = ['pdf']
                                setTimeout(() => document.getElementById(`${this.id}-file-input`).click(), 200);
                            },
                            video: () => {
                                this.accept = ['mp4', '3gpp']
                                setTimeout(() => document.getElementById(`${this.id}-file-input`).click(), 200);
                            },
                        },
                    },
                    "emoji-toolbar": {
                        buttonIcon: getFaIconString(faFaceSmile)
                    },
                    "emoji-shortname": true,
                },
            },
            quickAnswer: this.chat == null ? null : this.chat.suggest,
            accept: [],
            file: null,
            id: uuid.v4()
        }
    },
    props: {
        chat: {
            type: [Object, Array],
            required: true
        }
    },
    methods: {
        toast(message, type = 'danger') {
            if (type == 'error') type = 'danger';
            this.$bvToast.toast(message, {title: 'Respostas', variant: type})
        },
        sendFile() {
            this.loading = true;
            if(this.file.file.type.includes('image')) {
			    const canvas = this.$refs.cropper.getCroppedCanvas();
                return canvas.toBlob(blob => {
                    Object.assign(this.file, { file: new File([blob], "image.png", {type: 'image/jpeg'}) });
                    this.sendMessage();
                }, 'image/jpeg', 0.9);
            }
            this.sendMessage();
        },
        clearMessage(message) {
            const div = document.createElement('div');

            div.innerHTML = message;
            div.querySelectorAll('*').forEach((el) => {
                if(!['P', 'STRONG', 'EM', 'S', 'BR'].includes(el.tagName)) {
                    el.insertAdjacentText('afterend', el.innerText);
                    el.remove();
                }
                if(el.innerText.trim() == null || el.innerText.trim() == '')
                    el.remove();

            });
            div.querySelectorAll('p').forEach((el) => {
                el.insertAdjacentHTML('afterend', '<br>');
                el.insertAdjacentHTML('afterend', el.innerHTML);
                el.remove();
            });

            let text = div.innerHTML.trim();
            text = text.replaceAll(/[\u200B-\u200D\uFEFF]/g, '');
            return text;
        },
        sendMessage() {
            if ((this.message == null || this.message.length == 0) && !this.file)
                return this.loading = false;

            const phone = this.isMass ? this.chat.map(e => e.phone) : this.chat.phone;
            let message = this.file ? this.file.message : this.message;
            message = this.clearMessage(message)
            const suggest = this.chat.suggest;

            let payload = {
                phone: phone,
                suggest_id: suggest == null ? null : suggest._id,
                file: this.file ? this.file.file : null,
                file_url: suggest && suggest.attachment && suggest.attachment.url ? suggest.attachment.url : null,
                message
            };

            const formData = new FormData();
            Object.entries(payload).forEach(e => {
                if(e[1] != null) formData.append(e[0], e[1])
            });

            this.loading = true;
            api.post('/chats', formData)
            .then(res => {
                const {type, message} = res.data;

                if (type == 'success') {
                    this.quickAnswer = null;
                    Object.assign(this.chat, { suggest: null });
                    this.$bvModal.hide(`${this.id}-file-preview-modal`)
                    return this.message = '';
                }
                this.toast(message);
            })
            .catch(err => {
                console.log(err);
                var message = "Não foi possível enviar mensagem.";

                if (err.response) {
                    message = err.response.data.message;
                }
                this.toast(message);
            })
            .finally(() => this.loading = false);
        },

        removeTagsOnPaste(event) {
            let paste = (event.clipboardData || window.clipboardData).getData('text');
            const p = document.createElement('p');
            p.innerHTML = paste;
            const text = p.innerText.trim();
            const selection = window.getSelection();
            if (!selection.rangeCount) return;
            selection.deleteFromDocument();
            selection.getRangeAt(0).insertNode(document.createTextNode(text));
        },
        changeStatus(status) {
            this.loading = true;
            api.put(`/chats/${this.chat._id}`, { status })
                .then(res => {
                    const {type, message, body} = res.data;

                    if (type == 'success') {
                        return Object.assign(this.chat, body);
                    }
                    this.toast(message);
                })
                .catch(err => {
                    console.log(err);
                    var message = "Não foi possível atualizar o chat.";

                    if (err.response) {
                        message = err.response.data.message;
                    }
                    this.toast(message);
                })
                .finally(() => this.loading = false)
        },
        denyAnswer() {
            const id = this.chat.suggest._id;

            this.loading = true;
            api.delete(`/chats/suggests/${id}`)
            .then(res => {
                const { type } = res.data;

                if(type == 'success') {
                    this.quickAnswer = null;
                    this.message = null;
                    return Object.assign(this.chat, { suggest: null });
                }
                throw res;
            })
            .catch(err => {
                let message = 'Não foi possível recusar sugestão, tente novamente.';
                if(err.response) message = err.response.data.message;

                this.toast(message);
            })
            .finally(() => this.loading = false);
        },
        onInputFile(event) {
            const accepted = Object.values(acceptedMimes).flat();

            if (!(event.target.files && event.target.files[0])) return;

            const file = event.target.files[0];
            event.target.value = null;
            const ext = file.name.split('.').pop();

            if(!this.accept.includes(ext)) {
                return this.$swal('Formato inválido', `O formato do arquivo não é suportado. [${accepted.join(', ')}]`, 'error');
            }

            if(acceptedMimes.image.includes(ext) && file.size && Number(file.size) / (1024 * 1024) > 5) {
                return this.$swal('Arquivo muito grande', `Somente imagens de até 5 MB são aceitos.`, 'error');
            }
            if(acceptedMimes.video.includes(ext) && file.size && Number(file.size) / (1024 * 1024) > 15) {
                return this.$swal('Arquivo muito grande', `Somente vídeos de até 15 MB são aceitos.`, 'error');
            }
            if(acceptedMimes.document.includes(ext) && file.size && Number(file.size) / (1024 * 1024) > 50) {
                return this.$swal('Arquivo muito grande', `Somente arquivos de até 50 MB são aceitos.`, 'error');
            }

            this.file = {
                file,
                message: this.file != null ? this.file.message : ''
            };
        }
    },
    computed: {
        chatType() {
            const status = this.$props.chat.status || 'active';
            return chatTypes.find((value) => value.id == status);
        },
        isMass() {
            if(this.$props.chat == null) return false;
            return this.$props.chat.constructor == Array;
        },
        src() {
            if(this.file) {
                return URL.createObjectURL(this.file.file);
            }
            return null;
        }
    },
    mounted() {
        this.$root.$on('quick-answer', (answer) => {
            this.quickAnswer = answer;
            this.message = answer.root_text;
        });
    },
    watch: {
        message(val) {
            if((val == null || val.length == 0) && this.chat.suggest == null) this.quickAnswer = null;
        },
        '$props.chat.suggest': {
            handler(newVal, oldVal) {
                if(newVal == null) {
                    this.quickAnswer = null;
                    return this.message = null
                }

                const oldId = oldVal ? oldVal._id : null;
                const newId = newVal._id;

                if (newId != oldId) {
                    this.quickAnswer = newVal;
                    return this.message = newVal.message
                }
            },
            deep: true
        }
    }
}
</script>

<style lang="scss">
    .quillEditorContainer {
        .ql-snow .ql-tooltip, #emoji-palette {
            bottom: 0 !important;
            top: unset !important;
            left: unset !important;
        }
    }
</style>
