<template>
    <span role="button" class="d-flex align-items-center justify-content-center">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            :width="width"
            :height="height"
            viewBox="0 0 22 24"
            :fill="iconColor"
            :aria-labelledby="iconName"
            role="presentation"
        >
            <g>
                <component :is="icon"/>
            </g>
        </svg>
    </span>
</template>

<script>
import Icon1 from './icon-1.vue';
import Icon2 from './icon-2.vue';
import Icon3 from './icon-3.vue';
import Icon4 from './icon-4.vue';
import Icon5 from './icon-5.vue';
import Icon6 from './icon-6.vue';
import Icon7 from './icon-7.vue';
import Icon8 from './icon-8.vue';
import Icon9 from './icon-9.vue';
import Icon10 from './icon-10.vue';
import Icon11 from './icon-11.vue';

const icons = {
    'icon-1': Icon1,
    'icon-2': Icon2,
    'icon-3': Icon3,
    'icon-4': Icon4,
    'icon-5': Icon5,
    'icon-6': Icon6,
    'icon-7': Icon7,
    'icon-8': Icon8,
    'icon-9': Icon9,
    'icon-10': Icon10,
    'icon-11': Icon11
}

export default {
    props: {
        iconName: {
            type: String,
            default: "box",
        },
        width: {
            type: [Number, String],
            default: 24,
        },
        height: {
            type: [Number, String],
            default: 24,
        },
        iconColor: {
            type: String,
            default: "#999999",
        },
    },
    computed: {
        icon() {
            return icons[this.iconName]
        }
    }
};
</script>

<style scoped lang="scss">
svg {
    display: inline-block;
    vertical-align: baseline;
    margin-bottom: -2px; /* yes, I'm that particular about formatting */
    transition: 200ms;
}

svg:hover {
    fill: #0076FF;
}

span {
    outline: none;
    border: none;
    box-shadow: none;
}
</style>
